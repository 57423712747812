button[mat-icon-button].rotateable > span {
  display: flex;
  align-items: center;
  justify-content: center;
}

button[mat-icon-button].rotating {
  -webkit-animation: spin 1.5s linear infinite;
  -moz-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(-360deg);
    }
  }

  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(-360deg);
    }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }
}

mat-form-field.mat-mdc-form-field.no-hint-padding > .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

// ==== Dialog Sizes ====

.cdk-overlay-pane.dialog-nopadding {
  padding: 0px !important;
  max-width: none !important;
}

.cdk-overlay-pane.dialog-medium-size {
  width: calc(100% - 4em) !important;
  height: calc(100% - 4em) !important;
  max-width: 850px !important;
  max-height: 750px !important;
}

.cdk-overlay-pane.dialog-max-size {
  width: calc(100% - 4em) !important;
  height: calc(100% - 4em) !important;
  max-width: 1200px !important;
  max-height: 1000px !important;
}
