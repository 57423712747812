@use '@angular/material' as mat;
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();

$scb-contrast: (
  50: #000000,
  100: #000000,
  200: #000000,
  300: #ffffff,
  400: #ffffff,
  500: #ffffff,
  600: #ffffff,
  700: #ffffff,
  800: #ffffff,
  900: #ffffff,
  A100: #000000,
  A200: #000000,
  A400: #ffffff,
  A700: #ffffff,
);
$scb-primary: (
  30: var(--primary-03),
  50: var(--primary-05),
  100: var(--primary-10),
  200: var(--primary-20),
  300: var(--primary-30),
  400: var(--primary-40),
  500: var(--primary-50),
  600: var(--primary-60),
  650: var(--primary-65),
  700: var(--primary-70),
  750: var(--primary-75),
  800: var(--primary-80),
  900: var(--primary-90),
  A100: var(--accent-1),
  A200: var(--accent-2),
  A400: var(--accent-3),
  A700: var(--accent-4),
  contrast: $scb-contrast,
);
$scb-accent: (
  50: #eeeeee,
  100: #d4d4d4,
  200: #b7b7b7,
  300: #9a9a9a,
  400: #858585,
  500: #6f6f6f,
  600: #676767,
  700: #5c5c5c,
  800: #525252,
  900: #404040,
  A100: #f5a8a8,
  A200: #f07a7a,
  A400: #ff3838,
  A700: #ff1f1f,
  contrast: $scb-contrast,
);

$scb-warn: (
  50: #fce0e2,
  100: #f7b3b7,
  200: #f18087,
  300: #eb4d57,
  400: #e72633,
  500: #e3000f,
  600: #e0000d,
  700: #dc000b,
  800: #d80008,
  900: #d00004,
  A100: #fff8f8,
  A200: #ffc5c5,
  A400: #ff9292,
  A700: #ff7979,
  contrast: $scb-contrast,
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$scb-primary: mat.m2-define-palette($scb-primary, /* default */ 800, /* lighter */ 300, /* darker */ 900);
$scb-accent: mat.m2-define-palette($scb-accent);
$scb-warn: mat.m2-define-palette($scb-warn);

$scb-dark-primary: mat.m2-define-palette($scb-primary, 200, 30, 400);
$scb-dark-accent: mat.m2-define-palette($scb-accent);
$scb-dark-warn: mat.m2-define-palette($scb-warn, 600);

$typography: mat.m2-define-typography-config(
  $font-family: 'Arial, "Helvetica Neue", sans-serif',
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$scb-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $scb-primary,
      accent: $scb-accent,
      warn: $scb-warn,
    ),
    topography: $typography,
  )
);

$scb-dark: mat.m2-define-dark-theme(
  (
    color: (
      primary: $scb-dark-primary,
      accent: $scb-dark-accent,
      warn: $scb-dark-warn,
    ),
  )
);
// turn all the lights on
@include mat.all-component-themes($scb-theme);

// enter the dark-ages
.dark {
  @include mat.all-component-colors($scb-dark);
}

// shrink input fields height
* {
  --mat-form-field-container-height: 36px !important;
  --mat-form-field-container-vertical-padding: 8px !important;
}
