//***********************************************************************************
//
// Content-Structure:                      =C
// ----------
// Buttons                                  B
//     Main Navigation                          N
//     Table Row Toolbar                        T
//     Overview                                 O
//     Usermanagement                           U
//     Contacts                                 C
//     Configuration                            CF
//     Messages                                 M
//     Fleetmap                                 F
//     Reports                                  RE
//     Geoobjects                               G
//     Pushing                                  PU
//     Shipper / Tenant                         S
//     Plate                                    PL
//     RFID                                     RF
// Dropdown Controls                        DC
// Documents                                D
// Arrows                                   A
// UI Elements                              UI
// Logbook                                  L
// Messages                                 M
// Trip-View                                TV
// Touranalysis                             TA
//     Events                                   E
//
// Navigate to main section by searching =[AZ] and to subsections (intended) by ==[AZ]
//
//***********************************************************************************

// Special colors
$success: #8bc62d;
$error: #e3000f;
$warning: #ffa500;

.icon::before {
  font-size: 1.5em;
  vertical-align: middle;
  //     &::before { font-size: 1.846rem; }
  //     &.arrow::before { font-size: .769rem; }
}

.icon-large::before {
  font-size: 1.846rem;
  vertical-align: middle;
}

.icon-margin-right::before{
  margin-right: 5px;
}

.btn-icon::before {
  font-size: 20px;
  padding-right: 0px;
  vertical-align: middle;
}

.prefix-icon::before {
  vertical-align: top;
  font-size: 24px;
}

// .ui::before { line-height: 1px; }

// Buttons (=B)
// --------------------------------------------------

// Main Navigation (==N)
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

.icon-overview::before {
  content: '\46';
  font-family: 'ui_elements';
}

.icon-messages::before {
  content: '\49';
  font-family: 'ui_elements';
}

.icon-config::before {
  content: '\4e';
  font-family: 'ui_elements';
}

.icon-reports::before {
  content: '\47';
  font-family: 'ui_elements';
}

.icon-usermanagement::before {
  content: '\53';
  font-family: 'ui_elements';
}

.icon-contacts::before {
  content: '\b0';
  font-family: 'ui_elements';
}

// Table Row Toolbar (==T)
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

.icon-details::before {
  content: '\4c';
  font-family: 'ui_elements';
}

.icon-archive::before {
  content: '\50';
  font-family: 'ui_elements';
}

.icon-trip::before {
  content: '\42';
  font-family: 'ui_elements';
}

.icon-analyse-light::before {
  content: '\52';
  font-family: 'ui_elements';
}

.icon-pos-history::before {
  content: '\5b';
  font-family: 'ui_elements';
}

.icon-messages-light::before {
  content: '\4a';
  font-family: 'ui_elements';
}

.icon-config-light::before {
  content: '\4f';
  font-family: 'ui_elements';
}

.icon-vehicle-control::before {
  content: '\ae';
  font-family: 'ui_elements';
}

.icon-reports-light::before {
  content: '\48';
  font-family: 'ui_elements';
}

.icon-idea::before {
  content: '\4d';
  font-family: 'ui_elements';
}

.icon-ping::before {
  content: '\54';
  font-family: 'ui_elements';
}

// Overview (==O)
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

.icon-fleetmap::before {
  content: '\4b';
  font-family: 'ui_elements';
}

.icon-profile-view::before {
  content: '\44';
  font-family: 'ui_elements';
}

// Vehicle Groups Modal
.icon-group-view::before {
  content: '\43';
  font-family: 'ui_elements';
}

.icon-group-add::before {
  content: '\68';
  font-family: 'ui_elements';
}

.icon-group::before {
  content: '\69';
  font-family: 'ui_elements';
}

.icon-truck-remove::before {
  content: '\6a';
  font-family: 'ui_elements';
}

.icon-truck-add::before {
  content: '\6b';
  font-family: 'ui_elements';
}

// Usermanagement (==U)
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

.icon-user::before {
  content: '\55';
  font-family: 'ui_elements';
}

.icon-permission-sets::before {
  content: '\a1';
  font-family: 'ui_elements';
}

.icon-permission-sets-light::before {
  content: '\33';
  font-family: 'cbt_reefer';
}

.icon-add-user::before {
  content: '\30';
  font-family: 'ui_elements';
}

.icon-delete-user::before {
  content: '\39';
  font-family: 'ui_elements';
}

.icon-user-light::before {
  content: '\56';
  font-family: 'ui_elements';
}

.icon-orga_num::before {
  content: '\63';
  font-family: 'ui_elements';
}

.icon-orga::before {
  content: '\62';
  font-family: 'ui_elements';
}

.icon-lock::before {
  content: '\e6';
  font-family: 'ui_elements';
}

// Contacts (==C)
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

.icon-add-contact::before {
  content: '\30';
  font-family: 'ui_elements';
}

.icon-delete-contact::before {
  content: '\39';
  font-family: 'ui_elements';
}

.icon-add-contact-to-existing-contactlist::before {
  content: '\27';
  font-family: 'ui_elements';
}

.icon-add-contact-to-new-contactlist::before {
  content: '\23';
  font-family: 'ui_elements';
}

.icon-delete-contact-from-contactlist::before {
  content: '\2e';
  font-family: 'ui_elements';
}

.icon-add-contactlist::before {
  content: '\2c';
  font-family: 'ui_elements';
}

.icon-delete-contactlist::before {
  content: '\3e';
  font-family: 'ui_elements';
}

.icon-contactlist::before {
  content: '\40';
  font-family: 'ui_elements';
}

.icon-email::before {
  content: '\4a';
  font-family: 'ui_elements';
}

// Configuration (==CF)
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// Icons for subnavigation
.icon-deviceconfig::before {
  content: '\7d';
  font-family: 'ui_elements';
}

.icon-geofences::before {
  content: '\7b';
  font-family: 'ui_elements';
}

// Vehicle config table toolbar icons
.icon-reset-config::before {
  content: '\ba';
  font-family: 'ui_elements';
}

.icon-edit-config::before {
  content: '\a9';
  font-family: 'ui_elements';
}

.icon-edit-template::before {
  content: '\e5';
  font-family: 'ui_elements';
}

.icon-add-template::before {
  content: '\ab';
  font-family: 'ui_elements';
}

// Tile icons for settings
.icon-period-filter::before {
  content: '\7e';
  font-family: 'ui_elements';
}

.icon-mail-config::before {
  content: '\49';
  font-family: 'ui_elements';
}

.icon-mobile-config::before {
  content: '\a5';
  font-family: 'ui_elements';
}

// Workflow icons
.icon-config-note::before {
  content: '\b1';
  font-family: 'ui_elements';
}

.icon-config-confirm::before {
  content: '\b5';
  font-family: 'ui_elements';
}

// Icons for event status
.icon-config-error::before {
  content: '\201c';
  font-family: 'ui_elements';
}

.icon-config-ok::before {
  content: '\29';
  font-family: 'ui_elements';
}

.icon-TS::before,
.icon-days::before {
  content: '\bb';
  font-family: 'ui_elements';
}

// Messages (==M)
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

.icon-messages-confirm::before {
  content: '\f8';
  font-family: 'ui_elements';
}

// Fleetmap (==F)
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

.icon-filter::before {
  content: '\cc';
  font-family: 'ui_elements';
}

.icon-search::before {
  content: '\cf';
  font-family: 'ui_elements';
}

.icon-street::before {
  content: '\cd';
  font-family: 'ui_elements';
}

.icon-message-age::before {
  content: '\c5';
  font-family: 'ui_elements';
}

.icon-cluster::before {
  content: '\e7';
  font-family: 'ui_elements';
}

.icon-satellite::before {
  content: '\c7';
  font-family: 'ui_elements';
}

.icon-trafficlayer::before {
  content: '\2026';
  font-family: 'ui_elements';
}

.icon-scale::before {
  content: '\bf';
  font-family: 'ui_elements';
}

.icon-zoom::before {
  content: '\45';
  font-family: 'ui_elements';
}

// Reports (==RE)
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

.icon-delete-report-template::before {
  content: '\5c';
  font-family: 'ui_elements';
}

.icon-add-report-template::before {
  content: '\d9';
  font-family: 'ui_elements';
}

.icon-cyclical-reports::before {
  content: '\f7';
  font-family: 'ui_elements';
}

.icon-report-templates::before {
  content: '\d8';
  font-family: 'ui_elements';
}

.icon-cyclical-reports-light::before {
  content: '\5e';
  font-family: 'ui_elements';
}

.icon-vehicle-config-templates-light::before,
.icon-report-templates-light::before {
  content: '\b7';
  font-family: 'ui_elements';
}

.icon-vehicle-group-light::before {
  content: '\58';
  font-family: 'ui_elements';
}

// Geoobjects (==G)
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

.icon-geofences-light::before {
  content: '\70';
  font-family: 'ui_elements';
}

.icon-add-geofence::before {
  content: '\6d';
  font-family: 'ui_elements';
}

.icon-remove-geofence::before {
  content: '\6e';
  font-family: 'ui_elements';
}

// Pushing (==PU)
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

.icon-pushing::before {
  content: '\6f';
  font-family: 'ui_elements';
}

// Shipper / Tenant (==S)
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

.icon-edit-assignments::before {
  content: '\3c';
  font-family: 'ui_elements';
}

.icon-create-assignment::before {
  content: '\3b';
  font-family: 'ui_elements';
}

.icon-delete-assignments::before {
  content: '\3a';
  font-family: 'ui_elements';
}

.icon-relations::before {
  content: '\2f';
  font-family: 'ui_elements';
}

.icon-shipper-tenant::before {
  content: '\df';
  font-family: 'ui_elements';
}

// Plate (==PL)
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

.icon-VP-menu::before {
  content: '\7c';
  font-family: 'ui_elements';
}

// RFID (==RF)
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

.icon-TTL-menu::before {
  content: '\5d';
  font-family: 'ui_elements';
}

// Dropdown Controls (=DC)
// --------------------------------------------------

.icon-copy::before {
  content: '\24';
  font-family: 'ui_elements';
}

.icon-edit::before {
  content: '\a7';
  font-family: 'ui_elements';
}

.icon-trash::before {
  content: '\22';
  font-family: 'ui_elements';
}

.icon-deactivate::before {
  content: '\a3';
  font-family: 'ui_elements';
}

// Documents (=D)
// --------------------------------------------------

.icon-xls-load::before {
  content: '\2b';
  font-family: 'ui_elements';
}

.icon-csv-load::before {
  content: '\2a';
  font-family: 'ui_elements';
}

.icon-pdf-load::before {
  content: '\3f';
  font-family: 'ui_elements';
}

.icon-xls::before {
  content: '\41';
  font-family: 'ui_elements';
}

.icon-csv::before {
  content: '\79';
  font-family: 'ui_elements';
}

.icon-pdf::before {
  content: '\78';
  font-family: 'ui_elements';
}

// Arrows (=A)
// --------------------------------------------------

.icon-arrow-double-left::before {
  content: '\38';
  font-family: 'ui_elements';
}

.icon-arrow-double-right::before {
  content: '\37';
  font-family: 'ui_elements';
}

.icon-arrow-down::before {
  content: '\36';
  font-family: 'ui_elements';
}

.icon-arrow-left::before {
  content: '\35';
  font-family: 'ui_elements';
}

.icon-arrow-right::before {
  content: '\34';
  font-family: 'ui_elements';
}

.icon-arrow-up::before {
  content: '\33';
  font-family: 'ui_elements';
}

// UI Elements (=UI)
// --------------------------------------------------

.icon-save::before {
  content: '\26';
  font-family: 'ui_elements';
}

.icon-menu::before {
  content: '\65';
  font-family: 'ui_elements';
}

.icon-map-mobile::before {
  content: '\25';
  font-family: 'ui_elements';
}

.icon-calendar::before {
  content: '\21';
  font-family: 'ui_elements';
}

.icon-checked::before {
  content: '\7a';
  font-family: 'ui_elements';
}

.icon-direction::before {
  content: '\77';
  font-family: 'ui_elements';
}

.icon-add::before {
  content: '\64';
  font-family: 'ui_elements';
}

.icon-delete-3::before {
  content: '\61';
  font-family: 'ui_elements';
}

.icon-error::before {
  color: $error;
  content: '\201c';
  font-family: 'ui_elements';
}

.icon-ok::before {
  color: $success;
  content: '\29';
  font-family: 'ui_elements';
}

.icon-error-light::before {
  color: $error;
  content: '\a2';
  font-family: 'ui_elements';
  font-size: 32px;
}

.icon-ok-light::before {
  color: $success;
  content: '\b6';
  font-family: 'ui_elements';
  font-size: 32px;
}

.icon-checkbox {
  content: '\74';
  font-family: 'ui_elements';
}

.icon-minus::before {
  content: '\71';
  font-family: 'ui_elements';
}

.icon-checked-light::before {
  content: '\72';
  font-family: 'ui_elements';
}

.icon-dummy::before {
  content: '\51';
  font-family: 'ui_elements';
}

.icon-download::before {
  content: '\32';
  font-family: 'ui_elements';
}

// Logbook (=L)
// --------------------------------------------------

// Icons for incident status in map
.icon-INCIDENT::before,
.icon-event-occurred::before {
  color: $error;
  content: '\201c';
  font-family: 'ui_elements';
}

.icon-CORRECTION::before,
.icon-event-fixed::before {
  color: $success;
  content: '\29';
  font-family: 'ui_elements';
}

// Table head icons
.icon-INCIDENT_STATE::before {
  content: '\2d';
  font-family: 'ui_elements';
}

.icon-CATEGORY::before {
  content: '\5f';
  font-family: 'ui_elements';
}

.icon-ERROR::before {
  content: '\6c';
  font-family: 'ui_elements';
}

.icon-ERROR_TEXT::before {
  content: '\28';
  font-family: 'ui_elements';
}

.icon-ERROR_PRIORITY::before {
  content: '\3d';
  font-family: 'ui_elements';
}

// Messages (=M)
// --------------------------------------------------

.icon-alert::before {
  content: '\201c';
  font-family: 'ui_elements';
}

.icon-close::before {
  content: '\31';
  font-family: 'ui_elements';
}

.icon-info::before {
  content: '\5a';
  font-family: 'ui_elements';
}

.icon-question-mark::before {
  content: '\59';
  font-family: 'ui_elements';
}

.icon-success::before {
  content: '\29';
  font-family: 'ui_elements';
}

.icon-warning::before {
  content: '\57';
  font-family: 'ui_elements';
}

.icon-ACKNOWLEDGE_WITH_COMMENT::before {
  content: '\4d';
  font-family: 'ui_elements';
}

// Trip-View (=TV)
// --------------------------------------------------

// Trip-Types
.icon-shiptrip::before {
  content: '\76';
  font-family: 'ui_elements';
}

.icon-traintrip::before {
  content: '\75';
  font-family: 'ui_elements';
}

.icon-drivetrip::before {
  content: '\77';
  font-family: 'ui_elements';
}

.icon-parktrip::before {
  content: '\73';
  font-family: 'ui_elements';
}

// Touranalysis (=TA)
// --------------------------------------------------

.icon-parking::before {
  content: '\67';
  font-family: 'ui_elements';
}

.icon-compass::before {
  content: '\66';
  font-family: 'ui_elements';
}
