:root {
  // Shades of blue
  --blue-base: #114194;
  --blue-dark: #122654;

  --primary: var(--blue-base);
  --primary-03: #f8f9fc;
  --primary-05: #e8edf5;
  --primary-10: #c6d1e5;
  --primary-20: #a0b3d4;
  --primary-30: #7a95c3;
  --primary-40: #5e7eb6;
  --primary-50: #4167a9;
  --primary-60: #3b5fa2;
  --primary-65: #36589b;
  --primary-70: #325498;
  --primary-75: #305191;
  --primary-80: #2a4a8f;
  --primary-90: #1c397e;

  --accent-1: var(--primary-40);
  --accent-2: var(--primary-90);
  --accent-3: var(--blue-base);
  --accent-4: var(--blue-dark);
}
