@import './colors.scss';
@import './material-theme.scss';
@import './angular.scss';
@import './fonts.scss';
@import './ui_elements';
@import './scrollbar.scss';

body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: #333333;
  overflow: hidden;
}
app-root {
  width: 100%;
  height: 100%;
  display: block;
}

body {
  // todo: possibly use new background 'scb_bg.jpg' that is identical with b2c
  // background-image: url('/assets/images/scb_bg.jpg');

  background-image: url('/assets/videos/video.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

* {
  font-family: Arial, Helvetica, sans-serif;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

router-outlet + * {
  margin: auto;
}
.page {
  flex: 1 0 0;
  overflow: auto;
  display: flex;
}
.flex-spacer {
  flex-grow: 1 !important;
}

.flex-1-0-0 {
  flex: 1 0 0;
}

.flex-2-0-0 {
  flex: 2 0 0;
}

.clickable:hover {
  cursor: pointer;
}

mat-checkbox.mat-checkbox {
  div.mat-checkbox-inner-container {
    width: 20px;
    height: 20px;
  }
}

.mat-flat-button.mat-button-disabled {
  color: rgba(255, 255, 255, 0.4) !important;
}

.button-row {
  button:not(:last-child):not([mat-icon-button]) {
    margin-right: 0.5em;
  }
}

.button-formfield-row {
  display: flex;

  button:not(:last-child):not([mat-icon-button]) {
    margin-right: 0.5em;
  }

  mat-form-field:not(:last-child),
  mat-checkbox:not(:last-child) {
    margin-right: 0.5em;
  }
}

.dialog-nopadding .mat-dialog-container {
  padding: 0px !important;
  overflow: hidden;
}

.flexRow {
  display: flex;
  flex-direction: row;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}
.f-ai-c {
  align-items: center;
}
.f-as-c {
  align-self: center;
}
.f-jc-c {
  justify-content: center;
}
.f-ai-s {
  align-items: flex-start;
}

.tooltip-linebreaks {
  white-space: pre-line;
}

@keyframes flash {
  0%,
  100%,
  50% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0.2;
  }
}

.flash {
  animation: flash 2s both;
}

.selected {
  background: #28549e !important;
  color: #fff !important;

  span {
    color: #fff !important;
  }

  .mat-checkbox-frame {
    border-color: #fff !important;
  }
}

.panel-border {
  border: 1px solid #cfd9ea;
  overflow: hidden;
}

.divider {
  border: 1px solid #6785ba;
  opacity: 0.5;

  &.vertical {
    display: inline-block;
    height: 30px;
    margin: 10px 5px;
    width: 1px;
  }

  &.horizontal {
    display: block;
    width: 30px;
    margin: 10px 5px;
    height: 1px;
  }
}

.badge {
  border: 1px solid #333;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: transparent;
  color: #333;
  display: inline-block;
  font-size: 0.923rem;
  font-weight: normal;
  line-height: 1;
  max-width: 60px;
  min-width: 30px;
  padding: 3px 7px;
  text-align: center;
  vertical-align: middle;
}

.badge-white {
  color: white;
  border-color: white;
}

.spinner-container {
  position: relative;
  flex: 1 0 auto;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

// Removes Firefox Autofill yellow Color
input {
  filter: none;
}

// Removes Chrome Autofill background
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #333333 inset;
}

input:-webkit-autofill:first-line {
  color: white;
}

.box {
  background-color: #333;
  display: flex;
  color: white;
}

.separator {
  align-self: stretch;
  flex: 0 0 1px;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 0 !important;

  &.h {
    margin: 0 1em;
  }
  &.v {
    margin: 1em 0;
  }
}

.alert {
  color: white;
  background: #28549e;
  font-weight: bold;
  margin-bottom: 20px;
  padding: 8px 15px;

  &.alert-danger {
    background: #e3000f;
  }

  .row {
    display: flex;
    align-items: center;
  }

  // Styling for the message-icon
  span {
    padding: 0 15px;

    &::before {
      font-size: 2.308rem;
      line-height: 1.4;
    }
  }
}

video.video-fullScreen {
  transition-duration: 1s;
  transition-property: opacity;
  transition-timing-function: ease;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}

// Teaser Styles
.teaser {
  margin: 2em;
  color: white;

  display: none;
  @media (min-width: 1280px) {
    display: block;
  }

  h1 {
    font-family: 'Arial Black', Arial, sans-serif;
    font-size: 4.154em;
    font-weight: bolder;
    margin-top: 0;
    line-height: 1.1;

    span {
      font-size: 0.5em;
      vertical-align: super;
    }
  }

  p {
    margin: 1em 0 0;
    font-size: 1.846em;
    white-space: nowrap;
  }
}

.header {
  display: flex;
  align-items: center;

  &.blue-border {
    border-left: 8px solid transparent;
    border-color: var(--primary);
  }
}

.play-button {
  position: absolute !important;
  bottom: 0;
  right: 20px;
  color: white;
}

.dialog-nopadding > .mat-dialog-container {
  padding: 0 !important;
  overflow: hidden;
  max-width: none !important;
}

mat-dialog-container,
.mat-mdc-dialog-surface {
  & > * {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    width: 100%;

    & > .content {
      padding: 0.5em;
      flex: 1 0 0px;

      & > * {
        margin: 0.5em;
      }
    }

    .header {
      display: flex;
      align-items: center;

      &.blue-border {
        border-left: 8px solid transparent;
        border-color: var(--primary);
      }
    }

    .title-wrapper {
      display: flex;
      align-items: center;
      flex: 1 0 0px;
      overflow: hidden;
      margin: 10px;

      h3 {
        flex: 1 1 0px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .title {
      font-style: bold;
      color: var(--primary);
    }

    & > .action-bar {
      /*Footer styles */
      display: flex;
      justify-content: flex-end;
      padding: 0.5em;
    }
  }
}

.margin-1-important {
  margin: 1em !important;
}

.margin-top-1 {
  margin-top: 1em;
}

.margin-bottom-1 {
  margin-bottom: 1em;
}

.margin-bottom-05 {
  margin-bottom: 0.5em;
}

.margin-left-025 {
  margin-left: 0.25em;
}

.margin-left-05 {
  margin-left: 0.5em;
}

.margin-right-025 {
  margin-right: 0.25em;
}

.margin-right-05 {
  margin-right: 0.5em;
}

.margin-right-1 {
  margin-right: 1em;
}

.margin-right-05-important {
  margin-right: 0.5em !important;
}

mat-stepper {
  background: none !important;

  .mat-step-label {
    color: white !important;
  }

  .mat-stepper-horizontal-line {
    border-top-color: rgba(255, 255, 255, 0.25);
  }

  .mat-horizontal-content-container {
    padding: 0;
  }

  .mat-horizontal-stepper-header-container {
    margin-bottom: 0.5em;
  }

  mat-step-header:first-of-type {
    padding-left: 0;
  }

  mat-step-header:last-of-type {
    padding-right: 0;
  }

  .mat-step-header {
    height: 50px;
  }

  .mat-step-header:hover:not([aria-disabled]) {
    background-color: rgba(255, 255, 255, 0.04);
  }
}

.error-icon {
  color: $error;
  user-select: none;
}

.password-tooltip {
  white-space: pre-wrap;
  max-width: 300px !important;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.overflow-hidden {
  overflow: hidden;
}

.large-icon {
  font-size: 45px;
  margin-right: 0.25em;

  &.icon-warning {
    color: $warning;
  }
}

.gap-05 {
  gap: 0.5em;
}

.panel-border-top {
  border-top: 1px solid var(--primary-20);
}

.panel-border-bottom {
  border-bottom: 1px solid var(--primary-20) !important;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.overflow-hidden-auto {
  overflow: hidden auto;
}

.padding-1 {
  padding: 1em;
}

.f-ai-s {
  align-items: flex-start;
}
