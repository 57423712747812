::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--primary-05);
  border-left: 1px solid var(--primary-20);
}

::-webkit-scrollbar-track-piece {
  background: var(--primary-05);
  border-left: 1px solid var(--primary-20);
}

::-webkit-scrollbar-thumb {
  $url-lines: '/assets/images/lines.png';
  background: var(--primary-50) url($url-lines) center center no-repeat;
  background-size: 10px 11px;
  display: block;
  width: 10px;
}

::-webkit-scrollbar-button {
  display: none;
}

// Scrollbar Styles: Windows
// --------------------------------------------------

*,
body,
textarea {
  scrollbar-color: var(--primary-50) var(--primary-05);
  scrollbar-arrow-color: var(--primary-50);
  scrollbar-face-color: var(--primary-50);
  scrollbar-shadow-color: var(--primary-50);
  scrollbar-track-color: var(--primary-05);
}
